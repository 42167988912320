<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" :sst="true" :total="totalDocs" :data="settings">
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
        </div>

        <template slot="thead">
          <vs-th>KeyWord</vs-th>
          <vs-th>Value</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].key">{{ data[indextr].key }}</vs-td>
            <vs-td :data="data[indextr].value">
              <vs-input
                class="w-full"
                name="key"
                v-validate="'required|max:0'"
                data-vv-validate-on="blur"
                maxlength="8"
                v-model="data[indextr].value"
              >
                {{ data[indextr].value }}
              </vs-input>
            </vs-td>

            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="Update" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="confirmUpdateRecord(data[indextr], indextr)"
                    icon="icon-check"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      settings: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedSetting: {},
      selectedIndex: 0,
      kmMax: 0,
      kmMin: 0,
      pMax: 0,
      pMin: 0,
    };
  },

  methods: {
    ...mapActions("admin", ["getSettingList", "updateSetting"]),
    moment(date) {
      return moment(date);
    },

    getSettingsList() {
      let self = this;
      this.getSettingList(self.dataTableParams).then((res) => {
        self.settings = res.data.data.docs;

        //not recommennded for larger data sets
        self.pMax = self.settings.find((x) => x.key === "Price Maximum").value;
        self.pMin = self.settings.find((x) => x.key === "Price Minimum").value;
        self.kmMax = self.settings.find(
          (x) => x.key === "KiloMeter Maximum"
        ).value;
        self.kmMin = self.settings.find(
          (x) => x.key === "KiloMeter Minimum"
        ).value;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
      });
    },

    confirmUpdateRecord(data, i) {
      this.selectedSetting = data;
      this.selectedIndex = i;
      let selectedRow = JSON.parse(JSON.stringify(data));
      if (selectedRow.key === "Price Maximum") {
        this.pMax = selectedRow.value;
      }
      if (selectedRow.key === "Price Minimum") {
        this.pMin = selectedRow.value;
      }
      if (selectedRow.key === "KiloMeter Maximum") {
        this.kmMax = selectedRow.value;
      }
      if (selectedRow.key === "KiloMeter Minimum") {
        this.kmMin = selectedRow.value;
      }

      const toCompare = selectedRow.key.match(/kilometer/gi);
      if (toCompare && parseInt(this.kmMin) < parseInt(this.kmMax)) {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: `Confirm Update`,
          text: `Update "${data.key}" value to : ${data.value} `,
          accept: this.updateSettingDetail,
          acceptText: "Update",
        });
      } else if (!toCompare && parseInt(this.pMin) < parseInt(this.pMax)) {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: `Confirm Update`,
          text: `Update "${data.key}" value to : ${data.value} `,
          accept: this.updateSettingDetail,
          acceptText: "Update",
        });
      } else {
        if (toCompare) {
          let msg = "";
          if (selectedRow.key === "KiloMeter Maximum") {
            msg = "Maximum KiloMeter must be greater than Minimum Kilometer";
          }
          if (selectedRow.key === "KiloMeter Minimum") {
            msg = "Minimum KiloMeter must be lower than Maximum Kilometer";
          }
          this.$vs.notify({
            title: "Error",
            text: msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          let msg = "";

          if (selectedRow.key === "Price Maximum") {
            msg =
              "Maximum Price Range must be greater than Minimum Price Range";
          }

          if (selectedRow.key === "Price Minimum") {
            msg = "Minimum Price Range must be lower than Maximum Price Range";
          }
          this.$vs.notify({
            title: "Error",
            text: msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },

    updateSettingDetail() {
      this.updateSetting(this.selectedSetting).then((res) => {
        this.$vs.notify({
          title: "Setting Updated",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.vehicles[this.selectedIndex] = this.res.data.data;
      });
    },

    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSettingsList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSettingsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSettingsList();
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSettingsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSettingsList();
      }
    },
  },
  created() {
    this.getSettingsList();
  },
};
</script>
